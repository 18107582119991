import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import vector from '../../../assets/icons/Vector.svg';

const useStyles = makeStyles((theme) => ({
  infoBoxWrapperBase: {
    borderRadius: 8,
    boxShadow: '0px 1px 23px 0px rgba(0, 0, 0, 0.17)',
    border: 'none',
    width: '100%',
    display: 'flex',
    cursor: 'pointer',
    transition: 'all 0.5s ease-in-out',
  },
  infoBoxWrapper: {
    marginBottom: theme.spacing(2),
  },
  infoBoxWrapperClosed: {
    marginBottom: 0,
  },
  infoBoxInfosText: {
    color: '#7b7f87',
    fontSize: 18,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
  },
  infoBoxInfos: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: theme.spacing(2.5, 3),
  },
  infoBoxInfosTitle: {
    color: '#4e5258',
    fontFamily: 'Nunito',
    fontSize: 20,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    alignItems: 'center',
    wordWrap: 'break-word',
  },
  titleWrapperBase: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    transition: 'all 0.5s ease-in-out',
  },
  titleWrapper: {
    marginBottom: theme.spacing(1),
  },
  titleWrapperClosed: {
    marginBottom: 0,
  },
  titleWrapperImg: {
    marginRight: theme.spacing(2),
  },
  vectorStyleBase: {
    width: '15px',
    height: '10px',
    marginLeft: '10rem',
    transition: 'transform 0.5s ease-in-out',
  },
  vectorStyle: {
    transform: 'rotate(180deg)',
  },
  vectorStyleStandard: {
    transform: 'rotate(0deg)',
  },
}));

function InfoBox(props) {
  const { icon, title, text, activeBox, index, handleClick, handleImage } = props;
  const isActive = activeBox === index;
  const classes = useStyles();

  if (isActive) handleImage(index);

  return (
    <Box
      className={`${classes.infoBoxWrapperBase} ${isActive ? classes.infoBoxWrapper : classes.infoBoxWrapperClosed}`}
      onClick={handleClick}
      component="div"
    >
      {isActive && <Box className={classes.colorColumn} component="div"></Box>}
      <Box className={classes.infoBoxInfos} component="div">
        <Box className={`${classes.titleWrapperBase} ${isActive ? classes.titleWrapper : classes.titleWrapperClosed}`} component="div">
          <img src={icon} alt="icon" className={classes.titleWrapperImg} />
          <Typography variant="h3" className={classes.infoBoxInfosTitle}>
            {title}
          </Typography>
          <img src={vector} className={`${classes.vectorStyleBase} ${isActive ? classes.vectorStyle : classes.vectorStyleStandard}`} />
        </Box>
        {isActive && (
          <Typography variant="body1" className={classes.infoBoxInfosText}>
            {text}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export default InfoBox;
