import React, { useEffect, useRef, useState } from "react";
import { useTheme } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { Swiper, SwiperSlide } from "swiper/react";
import Typography from "@material-ui/core/Typography";
import {
  Avatar,
  Button,
  Grid,
  IconButton,
  useMediaQuery,
} from "@material-ui/core";
import "swiper/css/bundle";
import LeftArrow from "../../../assets/icons/left-arrow.png";
import RightArrow from "../../../assets/icons/right-arrow.png";
import playButton from "../../../assets/icons/icon-play-button.svg";
import Juliana from "../../../assets/Juliana.jpeg"
import Leandra from '../../../assets/Leandra.jpeg'
import Fabricio from '../../../assets/Fabricio.jpeg'

const useStyles = makeStyles((theme) => ({
  feedbackBox: {
    width: "100%",
    borderRadius: "40px 0px 0px 40px",
    background: "#5CABC1",
    float: "right",
    padding: theme.spacing(10),
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down("sm")]: {
      borderRadius: "0",
      padding: theme.spacing(4),
      gap: 10,
    },
  },
  feedbackBoxHeader: {
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  headerTitle: {
    fontSize: 40,
    lineHeight: "60px",
    color: "#fff",
    fontWeight: 500,
    fontFamily: "Nunito",
    textAlign: "center",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
      lineHeight: "normal",
    },
  },
  feedbackBoxArrows: {
    width: "fit-content",
    justifyContent: "flex-end",
    gap: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {},
  },
  opinionBoxWrapper: {
    width: "100%",
  },
  opinionBoxButton: {
    height: 48,
    boxShadow: " 0px 4px 20px 0px rgba(0, 0, 0, 0.25)",
    textTransform: "none",
    color: "#7B7F87",
    fontWeight: 700,
    fontFamily: "Nunito",
    textAlign: "center",
    borderRadius: 8,
    [theme.breakpoints.down("sm")]: {
      marginTop: 40,
    },
  },
  opinionBoxContainer: {
    display: "flex",
    flexDirection: "row",
    transition: "transform 2s ease-out",
    gap: theme.spacing(5),
  },
  opinionBox: {
    borderRadius: 30,
    background: "#FFF",
    boxShadow: "0px 0px 25px 10px rgba(0, 0, 0, 0.15)",
    padding: theme.spacing(2, 3.5),
    gap: 10,
    height: "100%",
    width: "110%",
    maxWidth: "500px",
    minWidth: "296px",
    cursor: "grab",
    [theme.breakpoints.down("md")]: {
      maxWidth: 450,
      marginBottom: theme.spacing(2),
      boxShadow: "none",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      width: "20%",
      marginBottom: theme.spacing(2),
      boxShadow: "none",
    },
  },
  opinionBoxHeader: {
    overflow: "visible",
    maxHeight: "65px",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      maxHeight: "none",
      height: "auto",
      flexDirection: "column",
      justifyContent: "space-around",
      alignItems: "center",
    },
  },
  avatar: {
    width: 100,
    height: 100,
    bottom: 60,
    [theme.breakpoints.down("sm")]: {
      width: 80,
      height: 80,
      bottom: 0,
      top: -45,
      position: "absolute",
    },
  },
  testimonial: {
    color: "#7B7F87;",
    fontWeight: 400,
    fontFamily: "Nunito",
    fontSize: "18px",
    fontStyle: "italic",
  },
  clientName: {
    color: "#7B7F87;",
    fontWeight: 700,
    fontFamily: "Nunito",
    fontSize: "18px",
  },
  clientProf: {
    color: "#7B7F87;",
    fontWeight: 500,
    fontFamily: "Nunito",
    fontSize: "18px",
    textAlign: 'center',
  },
  clientInfo: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 auto",
  },
  centralized: {
    justifyContent: "center",
    alignItems: "center",
    margin: "0 auto",
  },
  swiperSlides:{
    padding: "0px 90px 30px 90px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px 0px 30px 0px",
    },
  }
}));

const testimonials = [
  {
    avatar: Juliana,
    testimonial:
      "“Agenda muito simples de configurar. Desde então eu consegui ter uma organização muito melhor dos agendamentos, porque o próprio paciente realiza o agendamento pelo aplicativo, pode cancelar consultas quando necessário e também efetuar o pagamento online. Me facilitou muito, já que não tenho secretária. Super indico!”",
    name: "Juliana Fonseca",
    url: "https://youtube.com/shorts/eOyw60x4tVs?si=g8UB-Bsq4GLVD5Yk",
  },
  {
    avatar: Leandra,
    testimonial:
      "“O aplicativo Prit veio para otimizar muito meu tempo e facilitar o meu dia a dia. Antigamente eu tinha que andar com uma agenda de papel, agora eles conseguem entrar no aplicativo e agendam instantaneamente o serviço. Eu gosto muito do aplicativo e tudo o que eu preciso sempre tem uma pessoa disponível para me ajudar!”",
    name: "Leandra Rosa",
    url: "https://youtube.com/shorts/8on9AFn3TlQ?si=IPPbAuol_EPVlsuH",
  },
  {
    avatar: Fabricio,
    testimonial:
      "“Me trouxe uma flexibilidade que eu não conseguia ter. Consigo dar retornos mais rápidos para o paciente em questão disponibilidade de agenda, pois muitas vezes a agenda física impede que a gente dê esses retornos em tempo real. O aplicativo me trouxe uma segurança e hoje eu não vivo sem!”",
    name: "Fabricio Borges",
    url: "https://youtube.com/shorts/k9fLllKflZE?si=fQWD5qXQ26-5VNKC",
  },
];

function FeedBackSection() {
  const classes = useStyles();
  const theme = useTheme();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [leftArrowOpacity, setLeftArrowOpacity] = useState(1);
  const [rightArrowOpacity, setRightArrowOpacity] = useState(1);
  const sliderRef = useRef(null);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  function OpinionBox({ avatar, testimonial, name, url }) {
    return (
      <Grid className={classes.opinionBox} container>
        <Grid className={classes.opinionBoxHeader} container>
          <Avatar className={classes.avatar} src={avatar}></Avatar>
          <Button className={classes.opinionBoxButton} onClick={() => { window.open(url); }}
          >
            <IconButton>
              {" "}
              <img src={playButton} alt="play-button" />
            </IconButton>{" "}
            <Typography>Ver depoimento</Typography>
          </Button>
        </Grid>
        <Typography className={classes.testimonial}>{testimonial}</Typography>
        <Grid className={classes.clientInfo}>
          <Typography className={classes.clientName}>{name}</Typography>
        </Grid>
      </Grid>
    );
  }

  const handleNext = () => {
    if (sliderRef.current && sliderRef.current.swiper) {
        const swiper = sliderRef.current.swiper;
        if (currentSlide < swiper.slides.length - 1) {
            setCurrentSlide(currentSlide + 1);
        }
    }
};

  const handlePrevious = () => {
    if (currentSlide > 0) {
      setCurrentSlide(currentSlide - 1);
    }
  };
  
  const handleSlideChange = (swiper) => {
    setCurrentSlide(swiper.realIndex);
  };

  const updateArrowsOpacity = () => {
    if (sliderRef.current && sliderRef.current.swiper) {
      const swiper = sliderRef.current.swiper;
      setLeftArrowOpacity(swiper.isBeginning ? 0.5 : 1);
      setRightArrowOpacity(swiper.isEnd ? 0.5 : 1);
    }
  };

  useEffect(() => {
    sliderRef.current.swiper.slideTo(currentSlide, 500, false);
    updateArrowsOpacity();
  }, [currentSlide]);

  return (
    <Grid className={classes.feedbackBox} container id="cases" data-aos={isMobile ? "" : "fade-in"} data-aos-duration="1200">
      <Grid className={classes.feedbackBoxHeader} container>
        <Typography variant="h2" className={classes.headerTitle}>
          O que nossos clientes dizem sobre nós?
        </Typography>
        {!isMobile && (
          <Grid className={classes.feedbackBoxArrows} container>
            <IconButton onClick={handlePrevious}>
              <img src={LeftArrow} alt="previous-arrow" style={{ opacity: leftArrowOpacity }}/>
            </IconButton>
            <IconButton onClick={handleNext}>
              <img src={RightArrow} alt="next-arrow" style={{ opacity: rightArrowOpacity }}/>
            </IconButton>
          </Grid>
        )}
      </Grid>
      <Swiper
        ref={sliderRef}
        style={{ padding: "55px 0 0 0" }}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          370: {
            slidesPerView: 1.05,
            spaceBetween: 10,
          },
          480: {
            slidesPerView: 1.4,
            spaceBetween: 20,
          },
          550: {
            slidesPerView: 1.3,
            spaceBetween: 20,
          },
          600: {
            slidesPerView: 1.8,
            spaceBetween: 10,
          },
          1000: {
            slidesPerView: 1.5,
            spaceBetween: 20,
          },
          1300: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1500: {
            slidesPerView: 2.5,
            spaceBetween: 20,
          },
          1900: {
            slidesPerView: 2.5,
            spaceBetween: 20,
          },
        }}
        onSlideChange={(swiper) => {
          handleSlideChange(swiper)
        }}
      >
        {testimonials.map((testimonial, index) => (
          <SwiperSlide key={index} className={classes.swiperSlides}>
            <OpinionBox
              url={testimonial.url}
              avatar={testimonial.avatar}
              testimonial={testimonial.testimonial}
              name={testimonial.name}
              profession={testimonial.profession}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      {isMobile && (
        <Grid
          className={classes.feedbackBoxArrows + " " + classes.centralized}
          container
        >
          <IconButton onClick={handlePrevious}>
            <img src={LeftArrow} alt="previous-arrow" style={{ opacity: leftArrowOpacity }}/>
          </IconButton>
          <IconButton onClick={handleNext}>
            <img src={RightArrow} alt="next-arrow" style={{ opacity: rightArrowOpacity }}/>
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
}

export default FeedBackSection;
