import React from "react";
import { Grid, Typography, Box, Button } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AiImage from "../../../assets/AiRobotZDoctors.png";
import BlueButton from "../../../components/common/blueButton";

const useStyles = makeStyles((theme) => ({
  appIntro: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: 'center',
    padding: theme.spacing(3, 5),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      padding: theme.spacing(2, 4),
    },
  },
  appIntroInfoTextBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: 'center',
    gap: theme.spacing(6),
    width: "35%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      gap: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
  },
  containerText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  appIntroInfosText: {
    fontFamily: "Nunito",
    fontWeight: 700,
    textAlign: 'center',
    lineHeight: "56.5px",
    color: "#595A5C",
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
      lineHeight: "34px",
    },
  },
  imgContainer:{
    display: 'flex',
    justifyContent: 'center',
    width:'50%',
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  appIntroDescription: {
    fontFamily: "Nunito",
    color: "#7B7F87",
    fontSize: 16,
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  appIntroButton: {
    backgroundColor: '#3B80E7',
    color: '#fff',
    padding: '0.5rem 1rem',
    fontSize: '1rem',
    borderRadius: '0.5rem',
    alignSelf: "flex-start",
    [theme.breakpoints.down("sm")]: {
      alignSelf: "center",
    },
  },
  appIntroImage: {
    maxWidth: "45%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  bold: {
    fontWeight: 800,
  },
}));

const AiSection = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid container className={classes.appIntro}>
      <div className={classes.containerText}>
        <Typography variant="h4" className={classes.appIntroInfosText}>
          Com a Assistente Virtual no WhatsApp, seus clientes marcam em segundos!
        </Typography>
      </div>
      <Grid
        item
        className={classes.appIntroInfoTextBox}
      >
        <Typography variant="h6" className={classes.appIntroDescription}>
          Agora, além da sua página de serviços, você tem uma{" "}
          <span className={classes.bold}>nova forma de agendar:</span> nossa
          Assistente Virtual no WhatsApp!<br /><br />
          Com um link exclusivo,{" "}
          <span className={classes.bold}>
            seus clientes podem marcar horários direto pelo WhatsApp,
          </span>{" "}
          sem complicação. Você escolhe o que divulgar: sua página completa ou o
          link do WhatsApp para facilitar ainda mais o agendamento.
        </Typography>
        <Button
          href="https://www.youtube.com/shorts/vSkOd-E6n0I"
          className={classes.appIntroButton}
        >Veja como funciona!</Button>
      </Grid>
      <div className={classes.imgContainer}>
        <Box
          component="img"
          src={AiImage}
          alt="Assistente Virtual"
          className={classes.appIntroImage}
        />
      </div>
    </Grid>
  );
};

export default AiSection;
