import React from "react";
import { useState } from "react"
import { Box, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import IconClock from "../../assets/icons/icon-clock.svg";
import IconCoin from "../../assets/icons/icon-coin.svg";
import IconGraphs from "../../assets/icons/icon-reports.svg";
import IconBell from "../../assets/icons/icon-bell.svg";
import BackMedicos from "../../assets/backDoctors.png";
import BackDentist from "../../assets/backDentists.png";
import BackNutrition from "../../assets/backNutrition.png";
import BackPsych from "../../assets/backPsych.png";
import IntroSection from "./introSection";
import VideoSection from "./videoSection";
import ClientEaseSection from "./clientEaseSection";
import ProfessionalsVideoSection from "./professionalsVideosSection";
import InfoSection from "./infoSection";
import SupportSection from "./supportSection";
import PricingSection from "./pricingSection";
import FooterSection from "./footerSection";
import PageHeader from "../../components/common/pageHeader";
import FeedBackSection from "./feedbackSection";

import "./index.css";
import AiSection from "./aiSection";

const InfoBoxData = [
  {
    key: "1",
    icon: IconClock,
    text: "Crie um link único da sua página para colocar nas suas redes sociais e fidelize seus pacientes deixando que eles agendem online com você 24h por dia, 7 dias por semana.",
    title: "Agendamento Online",
    delay: "600",
  },
  {
    key: "2",
    icon: IconCoin,
    text: "Com o Z Pay você solicita o pagamento parcial ou total para confirmação do agendamento e para de perder dinheiro com pacientes que cancelam de última hora.",
    title: "Cobrança Antecipada",
    delay: "700",
  },
  {
    key: "3",
    icon: IconBell,
    text: "Deixe que o Z Doctors lembre os seus pacientes da consulta com o envio dos lembretes automáticos e seja avisado sobre os novos agendamentos e pagamentos dos seus pacientes.",
    title: "Lembretes automáticos",
    delay: "800",
  },
  {
    key: "4",
    icon: IconGraphs,
    text: "Tenha o controle de todo o seu histórico de vendas e facilite a forma que você vê seu lucro diário, semanal e mensal.",
    title: "Controle Financeiro",
    delay: "900",
  },
];

const InfoVideosData = [
  {
    key: "1",
    background: BackMedicos,
    text: "Médicos",
  },
  {
    key: "2",
    background: BackPsych,
    text: "Psicólogos",
  },
  {
    key: "3",
    background: BackDentist,
    text: "Dentistas",
  },
  {
    key: "4",
    background: BackNutrition,
    text: "Nutricionistas",
  },
];

const useStyles = makeStyles((theme) => ({
  homePage: {
    width: "100%",
    height: "100vh",
    padding: 0,
  },
  spacingBox: {
    paddingLeft: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
    },
  },
  division: {
    width: "100%",
    background: "#7B7F87",
  },
}));

function Home() {
  const classes = useStyles();

  const [activeBox, setActiveBox] = useState(0);
  const handleToggleOpen = (index) => {
    setActiveBox(activeBox === index ? null : index);
  };

  return (
    <Container maxWidth={false} className={classes.homePage}>
      <PageHeader />
      <IntroSection />
      <VideoSection />
      <InfoSection
        InfoBoxData={InfoBoxData}
        handleToggleOpen={handleToggleOpen}
        activeBox={activeBox}
      />
      <AiSection/>
      <ClientEaseSection />
      <ProfessionalsVideoSection InfoVideosData={InfoVideosData} />
      <Box className={classes.spacingBox}>
        <FeedBackSection></FeedBackSection>
      </Box>
      <SupportSection />
      <PricingSection />
      <FooterSection />
    </Container>
  );
}

export default Home;
