import React, { useState } from 'react';
import { Grid, Typography, Box, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import InfoBox from '../../../components/common/infoBox';
import Vector from '../../../assets/icons/Vector.svg';
import CarouselComponent from '../../../components/common/carouselComponent/index';
import PhoneExampleInfo1 from '../../../assets/phoneExampleInfo4.png'
import PhoneExampleInfo3 from '../../../assets/phoneExampleInfo1.png';
import PhoneExampleInfo2 from '../../../assets/cobranca-antecipadaa.png';
import PhoneExampleInfo4 from '../../../assets/historico-vendasa.png';
import Bars from '../../../assets/linesDetails.png';

const useStyles = makeStyles((theme) => ({
  appInfos: {
    padding: theme.spacing(6, 5),
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2, 0.5),
      display: "flex",
      flexDirection: "column",
      justifyContent: 'center', 
      alignItems: 'center',
    },
  },
  appInfosTitle: {
    fontSize: 40,
    textAlign: "center",
    color: "#595A5C",
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: 300,
    margin: "0 auto",
    marginTop: 30,
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
    },
    [theme.breakpoints.down("md")]: {
    marginBottom: 30,
    },
  },
  appInfosBoxes: {
    display: "flex",
    flexDirection: "column",
    justifyContent: 'center',
    margin: "0 auto",
    padding: theme.spacing(0,1.5),
    [theme.breakpoints.up('md')]: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",

    },
  },
  divBoxes: {
    width: "100%",
    zIndex: 4,
    gap: 40,
    display: "flex",
    flexDirection: "column",
    marginBottom: 50,
    [theme.breakpoints.up('md')]: {
      width: "33%",
      gap: 70,
      zIndex: 4,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
    },
  },
  appIntroImage: {

    [theme.breakpoints.up('lg')]: {
      position: 'relative',
      right: 300,
      width: '500px',
    },
    [theme.breakpoints.between('md', 'lg')]: {
      position: 'relative',
      right: 150,
      width: '450px',
    },
    [theme.breakpoints.down('md')]: {
      position: 'relative',
      maxWidth: "100%",
      height: "auto",
      width: "auto",
      left: 220,
    },
    [theme.breakpoints.down('xs')]: {
      position: 'relative',
      maxWidth: "100%",
      height: "auto",
      width: "auto",
      left: 0,
    },

  },
  appIntroImage2: {
    maxWidth: "250%",
    height: "auto",
    width: "auto",
    position: 'relative',
    zIndex: 0,
    [theme.breakpoints.up('md')]: {
      position: 'relative',
      right: 300,
      zIndex: 0,
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: "100%",
      maxHeight: "200%",
      height: "auto",
      width: "auto",
      paddingRight: 10,
    },
  },
  appIntroImageBars: {
  
    position: 'absolute',
    right: 0,
    [theme.breakpoints.up('lg')]: {
      display: 'block',
      zIndex: '-1',
      position: 'absolute',
      right: 100,
      marginTop: 40,
      width: '1000px',
    },
    [theme.breakpoints.between('md', 'lg')]: {
      display: 'block',
      zIndex: '-1',
      position: 'absolute',
      right: 0,
      width: '850px',
    },
    [theme.breakpoints.down('md')]: {
      display: 'block',
      zIndex: '-1',
      position: 'absolute',
      right: 0,
      width: '750px',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      zIndex: '-1',
      position: 'absolute',
      width: '700px',
    },

  },
  bold: {
    fontWeight: 600,
  },
}));

const InfoSection = ({ InfoBoxData, handleToggleOpen, activeBox }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const [activeSlide, setActiveSlide] = useState(0);
  const phoneImages = [PhoneExampleInfo1, PhoneExampleInfo2, PhoneExampleInfo3, PhoneExampleInfo4];
  const activePhoneImage = phoneImages[activeSlide] || phoneImages[0];
  
  function handleSlideChange(newSlide){
    setActiveSlide(newSlide);
  };

  return (
    <Grid container className={classes.appInfos}  id='features'>
      {!isMobile ? (
          <Typography
          variant="h2"
          className={classes.appInfosTitle}
          data-aos={isMobile ? "" : "zoom-out-down"}
          data-aos-duration="800"
        >
          Funcionalidades que te economizam <span className={classes.bold}>tempo e dinheiro</span>!
        </Typography>
      ) :  <Typography
      variant="h2"
      className={classes.appInfosTitle}
      data-aos={isMobile ? "" : "zoom-out-down"}
      data-aos-duration="800"
    >
      Funcionalidades que te economizam <span className={classes.bold}>tempo</span>!
    </Typography>}
      <Grid container className={classes.appInfosBoxes}>
        {isMobile ? (
          <CarouselComponent carouselData={InfoBoxData} onSlideChange={handleSlideChange}/>
        ) : (
          <Grid item className={classes.divBoxes}       data-aos={isMobile ? "" : "fade-in"}
          data-aos-delay={'600'}>
            {InfoBoxData.map((box, index) => (
              <InfoBox
                handleClick={() => handleToggleOpen(index)}
                activeBox={activeBox}
                index={index}
                key={box.key}
                icon={box.icon}
                text={box.text}
                title={box.title}
                time={box.delay}
                handleImage={handleSlideChange}
              />
            ))}
          </Grid>
        )}
          <Grid item style={{alignItems: 'center', display: 'flex', justifyContent: 'center'}}>
            <Box
              component="img"
              src={Bars}
              alt="decorative bars"
              className={classes.appIntroImageBars}
              data-aos={isMobile ? "" : "fade-in"}
              data-aos-delay={"500"}
              data-aos-duration="800"
            />
            <Box
              component="img"
              src={activePhoneImage}
              alt="app working on phone"
              className={classes.appIntroImage}
              data-aos={isMobile ? "" : "fade-in"}
              data-aos-duration="1000"
            />
          </Grid>
      </Grid>
    </Grid>
  );
};

export default InfoSection;
